import React from 'react';
import axios from 'axios';
import { Table, Accordion } from 'react-bootstrap';
import { Button, Pagination, Loader, Modal, useToaster, Message } from 'rsuite';
import { InfoCircle } from 'react-bootstrap-icons';
import { v4 as uuidv4 } from 'uuid';

const limitOptions = [30, 50, 100];

const DisplayData = (props) => {
    const [modalVecOpened, setModalVecOpened] = React.useState(false);
    const [activePage, setActivePage] = React.useState(1);
    const [limit, setLimit] = React.useState(30);
    const [loader, setLoader] = React.useState(true);
    const [userData, setUserData] = React.useState({
        "id": 0,
        "ime": "",
        "priimek": "",
        "telefondoma": "",
        "telefonmobi": "",
        "telefonsluzba": "",
        "email": "",
        "spletnastran": "",
        "prevodi": [
        ]
    });

    const toaster = useToaster();
    const messageError = <Message type="error" header="Prišlo je do napake!">Prosimo poskusite znova.</Message>

    const baseURL = "https://iskalnik.dztps.si/express_backend/api/get/user"

    const openModalVec = (id) => {
        setLoader(true);
        setModalVecOpened(!modalVecOpened);
        axios.get(`${baseURL}/${id}`)
        .then((response) => {
            setUserData(response.data);
            setTimeout(() => {
                setLoader(false);
            }, 250);
        }).catch(error => {
            toaster.push(messageError, { placement: "topCenter" })
            console.error("error: ", error);
            setLoader(false);
        });
    }
    
    return (
        <div>
            <Pagination
                locale={{
                    limit: '{0}'
                }}
                layout={['-', 'pager', '|', 'limit']}
                prev
                last
                next
                first
                size="md"
                total={props.data.length}
                limit={limit}
                limitOptions={limitOptions}
                onChangeLimit={setLimit}
                activePage={activePage}
                onChangePage={setActivePage}
            />
            {props.firstLoad ?
            <Table bordered size="sm" responsive style={{marginTop: "2rem"}}>
                <thead style={{verticalAlign: "middle"}}>
                    <tr>
                        <th><InfoCircle size={18} /></th>
                        <th>Ime</th>
                        <th>Priimek</th>
                        <th>E-pošta</th>
                    </tr>
                </thead>
                <tbody>
                    {props.data.map((e, index) => {
                        return index >= limit*(activePage-1) && index < limit*activePage && <tr key={uuidv4()}>
                                <td style={{textAlign: "center"}}>
                                    <Button appearance="ghost" color="cyan" onClick={() => openModalVec(e.id)}>Več</Button>
                                </td>
                                <td>{e.ime}</td>
                                <td>{e.priimek}</td>
                                <td><a href={`mailto:${e.email}`}>{e.email}</a></td>
                            </tr>
                    })}
                </tbody>
            </Table>
            :
            <Table bordered size="sm" responsive style={{marginTop: "2rem"}}>
                <thead style={{verticalAlign: "middle"}}>
                    <tr>
                        <th rowSpan="2"><InfoCircle size={18} /></th>
                        <th rowSpan="2">Ime</th>
                        <th rowSpan="2">Priimek</th>
                        <th rowSpan="2">E-pošta</th>
                        <th rowSpan="2">Telefon</th>
                        <th colSpan="5">Prevod iz jezika v jezik</th>
                    </tr>
                    <tr>
                        <th>Rang</th>
                        <th>iz</th>
                        <th>v</th>
                        <th>Strokovno področje</th>
                        <th>Dodatne storitve</th>
                    </tr>
                </thead>
                <tbody>
                    {props.data.map((e, index) => {
                        let tempRowSpanNum = e.prevodi.length;
                        return e.prevodi.length !== 0 && (index >= limit*(activePage-1) && index < limit*activePage) &&
                            <React.Fragment key={uuidv4()}>
                                <tr>
                                    <td rowSpan={tempRowSpanNum}>
                                        <Button appearance="ghost" color="cyan" onClick={() => openModalVec(e.id)}>Več</Button>
                                    </td>
                                    <td rowSpan={tempRowSpanNum}>{e.ime}</td>
                                    <td rowSpan={tempRowSpanNum}>{e.priimek}</td>
                                    <td rowSpan={tempRowSpanNum}><a href={`mailto:${e.email}`}>{e.email}</a></td>
                                    <td style={{whiteSpace: "nowrap"}} rowSpan={tempRowSpanNum}>
                                        {e.telefonmobi.length > 0 && <>{e.telefonmobi}<br /></>}
                                        {e.telefondoma.length > 0 && <>{e.telefondoma}<br /></>}
                                        {e.telefonsluzba.length > 0 && <>{e.telefonsluzba}<br /></>}
                                    </td>
                                    { e.prevodi.length > 0 ? 
                                    <>
                                        <td>{e.prevodi[0].rang}</td>
                                        <td>{e.prevodi[0].iz}</td>
                                        <td>{e.prevodi[0].v}</td>
                                        <td>{props.iskanjeStrokovnoPodrocje.length === 0 ?
                                            <Accordion>
                                                <Accordion.Item eventKey="0">
                                                <Accordion.Header>Področja</Accordion.Header>
                                                <Accordion.Body>
                                                    {e.prevodi[0].strokovnopodrocje.map(z => {return <React.Fragment key={uuidv4()}>{z}<br /></React.Fragment>} )}
                                                </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion> :
                                                e.prevodi[0].strokovnopodrocje.map(z => {return <React.Fragment key={uuidv4()}>{props.iskanjeStrokovnoPodrocje.includes(z) && <>{z}</>}</React.Fragment>})
                                            
                                            }</td>
                                        {/* <td>{e.prevodi[0].strokovnopodrocje.map(z => {return props.iskanjeStrokovnoPodrocje.length === 0 ?
                                                <React.Fragment key={uuidv4()}>
                                                    {z} <br />
                                                </React.Fragment> :
                                                <React.Fragment key={uuidv4()}>{props.iskanjeStrokovnoPodrocje.includes(z) && <>{z}</>}</React.Fragment>} )}</td> */}
                                        <td>{e.prevodi[0].vloga.map(u => {return <React.Fragment key={uuidv4()}>{props.iskanjeVloga.includes(u) ? <b>{u}</b> : u} <br /></React.Fragment>})}</td>
                                    </>
                                    :
                                    <>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </>}
                                </tr>
                                {e.prevodi.map((a, index) => {
                                    return ( index > 0 && <React.Fragment key={uuidv4()}>
                                            <tr>
                                                <td>{a.rang}</td>
                                                <td>{a.iz}</td>
                                                <td>{a.v}</td>
                                                <td>{props.iskanjeStrokovnoPodrocje.length === 0 ?
                                                    <Accordion>
                                                        <Accordion.Item eventKey="0">
                                                        <Accordion.Header>Področja</Accordion.Header>
                                                        <Accordion.Body>
                                                            {a.strokovnopodrocje.map(z => {return <React.Fragment key={uuidv4()}>{z}<br /></React.Fragment>} )}
                                                        </Accordion.Body>
                                                        </Accordion.Item>
                                                    </Accordion> :
                                                        a.strokovnopodrocje.map(z => {return <React.Fragment key={uuidv4()}>{props.iskanjeStrokovnoPodrocje.includes(z) && <>{z}</>}</React.Fragment>})
                                                    
                                                    }</td>
                                                {/* <td>{a.strokovnopodrocje.map(z => {return props.iskanjeStrokovnoPodrocje.length === 0 ?
                                                <React.Fragment key={uuidv4()}>
                                                    {z} <br />
                                                </React.Fragment> :
                                                <React.Fragment key={uuidv4()}>{props.iskanjeStrokovnoPodrocje.includes(z) && <>{z}</>}</React.Fragment>} )}</td> */}
                                                <td>{a.vloga.map(u => {return <React.Fragment key={uuidv4()}>{props.iskanjeVloga.includes(u) ? <b>{u}</b> : u} <br /></React.Fragment>})}</td>
                                            </tr>
                                        </React.Fragment>
                                    )
                                })}
                            </React.Fragment>
                    })}
                </tbody>
            </Table>
            }
            <Pagination
                locale={{
                    limit: '{0}'
                }}
                layout={['-', 'pager', '|', 'limit']}
                prev
                last
                next
                first
                size="md"
                total={props.data.length}
                limit={limit}
                limitOptions={limitOptions}
                onChangeLimit={setLimit}
                activePage={activePage}
                onChangePage={setActivePage}
            />
            
            <Modal open={modalVecOpened} onClose={openModalVec}>
                {loader ? <Loader backdrop content="Nalagam ..." vertical /> :
                <>
                <Modal.Header>
                    <Modal.Title>{userData.ime} {userData.priimek}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <b>E-pošta:</b> <a href={`mailto:${userData.email}`}>{userData.email}</a><br />
                    {userData.telefonmobi !== "" && <><b>Telefon:</b> {userData.telefonmobi}<br /> </>}
                    {userData.telefondoma !== "" && <><b>Telefon:</b> {userData.telefondoma}<br /> </>}
                    {userData.telefonsluzba !== "" && <><b>Telefon:</b> {userData.telefonsluzba}<br /> </>}
                    {userData.spletnastran !== "" && <><b>Spletna stran:</b> {<a href={userData.spletnastran} target="_blank" rel="noreferrer noopener">{userData.spletnastran}</a>}<br /> </>}
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th colSpan="5">Prevod iz jezika v jezik</th>
                            </tr>
                            <tr>
                                <th>Rang</th>
                                <th>iz</th>
                                <th>v</th>
                                <th>Strokovno področje</th>
                                <th>Dodatne storitve</th>
                            </tr>
                        </thead>
                        <tbody>
                            {userData.prevodi !== undefined && userData.prevodi.map(e => {
                                return <tr key={uuidv4()}>
                                    <td>{e.rang}</td>
                                    <td>{e.iz}</td>
                                    <td>{e.v}</td>
                                    <td>{e.strokovnopodrocje.map(i => {return <React.Fragment key={uuidv4()}> {i} <br /></React.Fragment>})}</td>
                                    <td>{e.vloga.map(i => {return <React.Fragment key={uuidv4()}> {i} <br /></React.Fragment>})}</td>
                                </tr>
                            })}
                        </tbody>
                    </Table>
                </Modal.Body>
                </> }
                <Modal.Footer>
                    <Button appearance="primary" onClick={openModalVec}>
                        Zapri
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default DisplayData