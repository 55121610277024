export const strokovnoPodrocje = [
	{
		"label": "arheologija",
		"value": "arheologija"
	},
	{
		"label": "arhitektura",
		"value": "arhitektura"
	},
	{
		"label": "avtomobilizem",
		"value": "avtomobilizem"
	},
	{
		"label": "bančništvo",
		"value": "bančništvo"
	},
	{
		"label": "biologija",
		"value": "biologija"
	},
	{
		"label": "družboslovje",
		"value": "družboslovje"
	},
	{
		"label": "ekologija",
		"value": "ekologija"
	},
	{
		"label": "ekonomija",
		"value": "ekonomija"
	},
	{
		"label": "elektronika",
		"value": "elektronika"
	},
	{
		"label": "elektrotehnika",
		"value": "elektrotehnika"
	},
	{
		"label": "energetika",
		"value": "energetika"
	},
	{
		"label": "evropske zadeve",
		"value": "evropske zadeve"
	},
	{
		"label": "farmacija",
		"value": "farmacija"
	},
	{
		"label": "film",
		"value": "film"
	},
	{
		"label": "filozofija",
		"value": "filozofija"
	},
	{
		"label": "finance",
		"value": "finance"
	},
	{
		"label": "geografija",
		"value": "geografija"
	},
	{
		"label": "geologija",
		"value": "geologija"
	},
	{
		"label": "glasba",
		"value": "glasba"
	},
	{
		"label": "gledališče",
		"value": "gledališče"
	},
	{
		"label": "gozdarstvo",
		"value": "gozdarstvo"
	},
	{
		"label": "gradbeništvo",
		"value": "gradbeništvo"
	},
	{
		"label": "homeopatija",
		"value": "homeopatija"
	},
	{
		"label": "humanistične vede",
		"value": "humanistične vede"
	},
	{
		"label": "informacijska tehnologija",
		"value": "informacijska tehnologija"
	},
	{
		"label": "informatika",
		"value": "informatika"
	},
	{
		"label": "izobraževanje",
		"value": "izobraževanje"
	},
	{
		"label": "jezikoslovje",
		"value": "jezikoslovje"
	},
	{
		"label": "kadrovanje",
		"value": "kadrovanje"
	},
	{
		"label": "kemija",
		"value": "kemija"
	},
	{
		"label": "kemija in biologija",
		"value": "kemija in biologija"
	},
	{
		"label": "kemijska tehnologija",
		"value": "kemijska tehnologija"
	},
	{
		"label": "kinologija",
		"value": "kinologija"
	},
	{
		"label": "kmetijstvo",
		"value": "kmetijstvo"
	},
	{
		"label": "kmetijstvo in gozdarstvo",
		"value": "kmetijstvo in gozdarstvo"
	},
	{
		"label": "komunala",
		"value": "komunala"
	},
	{
		"label": "komunikologija",
		"value": "komunikologija"
	},
	{
		"label": "krajinska arhitektura",
		"value": "krajinska arhitektura"
	},
	{
		"label": "kulinarika",
		"value": "kulinarika"
	},
	{
		"label": "kultura",
		"value": "kultura"
	},
	{
		"label": "leposlovje",
		"value": "leposlovje"
	},
	{
		"label": "lesarstvo",
		"value": "lesarstvo"
	},
	{
		"label": "lesna industrija",
		"value": "lesna industrija"
	},
	{
		"label": "letalstvo",
		"value": "letalstvo"
	},
	{
		"label": "lokalizacija",
		"value": "lokalizacija"
	},
	{
		"label": "lovstvo",
		"value": "lovstvo"
	},
	{
		"label": "management",
		"value": "management"
	},
	{
		"label": "marketing",
		"value": "marketing"
	},
	{
		"label": "medicina",
		"value": "medicina"
	},
	{
		"label": "medicina in farmacija",
		"value": "medicina in farmacija"
	},
	{
		"label": "mednarodni odnosi",
		"value": "mednarodni odnosi"
	},
	{
		"label": "mehanika",
		"value": "mehanika"
	},
	{
		"label": "metalurgija",
		"value": "metalurgija"
	},
	{
		"label": "mikrobiologija",
		"value": "mikrobiologija"
	},
	{
		"label": "montažna gradnja",
		"value": "montažna gradnja"
	},
	{
		"label": "muzikologija",
		"value": "muzikologija"
	},
	{
		"label": "naravovarstvo",
		"value": "naravovarstvo"
	},
	{
		"label": "obramboslovje",
		"value": "obramboslovje"
	},
	{
		"label": "okolje",
		"value": "okolje"
	},
	{
		"label": "optoelektronika",
		"value": "optoelektronika"
	},
	{
		"label": "papirništvo",
		"value": "papirništvo"
	},
	{
		"label": "politika",
		"value": "politika"
	},
	{
		"label": "pravo",
		"value": "pravo"
	},
	{
		"label": "prehrana",
		"value": "prehrana"
	},
	{
		"label": "promet",
		"value": "promet"
	},
	{
		"label": "psihologija",
		"value": "psihologija"
	},
	{
		"label": "računalništvo",
		"value": "računalništvo"
	},
	{
		"label": "različne stroke",
		"value": "različne stroke"
	},
	{
		"label": "religija",
		"value": "religija"
	},
	{
		"label": "rudarstvo",
		"value": "rudarstvo"
	},
	{
		"label": "sistem kakovosti",
		"value": "sistem kakovosti"
	},
	{
		"label": "socialne zadeve",
		"value": "socialne zadeve"
	},
	{
		"label": "sociologija",
		"value": "sociologija"
	},
	{
		"label": "strojništvo",
		"value": "strojništvo"
	},
	{
		"label": "šolstvo",
		"value": "šolstvo"
	},
	{
		"label": "šport",
		"value": "šport"
	},
	{
		"label": "športna medicina",
		"value": "športna medicina"
	},
	{
		"label": "teatrologija",
		"value": "teatrologija"
	},
	{
		"label": "tehnični predpisi",
		"value": "tehnični predpisi"
	},
	{
		"label": "tehnika",
		"value": "tehnika"
	},
	{
		"label": "tekstil",
		"value": "tekstil"
	},
	{
		"label": "telekomunikacije",
		"value": "telekomunikacije"
	},
	{
		"label": "teologija",
		"value": "teologija"
	},
	{
		"label": "tiskarstvo",
		"value": "tiskarstvo"
	},
	{
		"label": "transkripcija gotice",
		"value": "transkripcija gotice"
	},
	{
		"label": "transport",
		"value": "transport"
	},
	{
		"label": "turizem",
		"value": "turizem"
	},
	{
		"label": "umetnost",
		"value": "umetnost"
	},
	{
		"label": "umetnostna zgodovina",
		"value": "umetnostna zgodovina"
	},
	{
		"label": "upravno-pravno podr.",
		"value": "upravno-pravno podr."
	},
	{
		"label": "urejanje prostora",
		"value": "urejanje prostora"
	},
	{
		"label": "varstvo okolja",
		"value": "varstvo okolja"
	},
	{
		"label": "veterina",
		"value": "veterina"
	},
	{
		"label": "vojaška terminologija",
		"value": "vojaška terminologija"
	},
	{
		"label": "zavarovalništvo",
		"value": "zavarovalništvo"
	},
	{
		"label": "zdravstven vzgoja",
		"value": "zdravstven vzgoja"
	},
	{
		"label": "zdravstvo",
		"value": "zdravstvo"
	},
	{
		"label": "zgodovina",
		"value": "zgodovina"
	},
	{
		"label": "živilska tehnologija",
		"value": "živilska tehnologija"
	}
]

export const jeziki = [
	{
		"label": "albanski",
		"value": "albanski"
	},
	{
		"label": "angleški",
		"value": "angleški"
	},
	{
		"label": "arabski",
		"value": "arabski"
	},
	{
		"label": "bolgarski",
		"value": "bolgarski"
	},
	{
		"label": "bosanski",
		"value": "bosanski"
	},
	{
		"label": "finski",
		"value": "finski"
	},
	{
		"label": "flamski",
		"value": "flamski"
	},
	{
		"label": "francoski",
		"value": "francoski"
	},
	{
		"label": "grški",
		"value": "grški"
	},
	{
		"label": "hebrejski",
		"value": "hebrejski"
	},
	{
		"label": "hrvaški",
		"value": "hrvaški"
	},
	{
		"label": "italijanski",
		"value": "italijanski"
	},
	{
		"label": "japonski",
		"value": "japonski"
	},
	{
		"label": "kitajski",
		"value": "kitajski"
	},
	{
		"label": "madžarski",
		"value": "madžarski"
	},
	{
		"label": "makedonski",
		"value": "makedonski"
	},
	{
		"label": "nemški",
		"value": "nemški"
	},
	{
		"label": "nizozemski",
		"value": "nizozemski"
	},
	{
		"label": "poljski",
		"value": "poljski"
	},
	{
		"label": "portugalski",
		"value": "portugalski"
	},
	{
		"label": "romunski",
		"value": "romunski"
	},
	{
		"label": "ruski",
		"value": "ruski"
	},
	{
		"label": "slovaški",
		"value": "slovaški"
	},
	{
		"label": "slovenski",
		"value": "slovenski"
	},
	{
		"label": "srbohrvaški",
		"value": "srbohrvaški"
	},
	{
		"label": "srbski",
		"value": "srbski"
	},
	{
		"label": "turški",
		"value": "turški"
	},
	{
		"label": "ukrajinski",
		"value": "ukrajinski"
	},
	{
		"label": "češki",
		"value": "češki"
	},
	{
		"label": "španski",
		"value": "španski"
	},
	{
		"label": "švedski",
		"value": "švedski"
	}
]

export const rang = [
    {
        "label": "A",
        "value": "A"
    },
    {
        "label": "B",
        "value": "B"
    },
    {
        "label": "C",
        "value": "C"
    },
    {
        "label": "D",
        "value": "D"
    },
    {
        "label": "E",
        "value": "E"
    },
    {
        "label": "F",
        "value": "F"
    },
    {
        "label": "G",
        "value": "G"
    },
    {
        "label": "H",
        "value": "H"
    },
]

export const abeceda = [
	{
		"value": "A",
		"label": "A"
	},
	{
		"value": "B",
		"label": "B"
	},
	{
		"value": "C",
		"label": "C"
	},
	{
		"value": "Č",
		"label": "Č"
	},
	{
		"value": "D",
		"label": "D"
	},
	{
		"value": "E",
		"label": "E"
	},
	{
		"value": "F",
		"label": "F"
	},
	{
		"value": "G",
		"label": "G"
	},
	{
		"value": "H",
		"label": "H"
	},
	{
		"value": "I",
		"label": "I"
	},
	{
		"value": "J",
		"label": "J"
	},
	{
		"value": "K",
		"label": "K"
	},
	{
		"value": "L",
		"label": "L"
	},
	{
		"value": "M",
		"label": "M"
	},
	{
		"value": "N",
		"label": "N"
	},
	{
		"value": "O",
		"label": "O"
	},
	{
		"value": "P",
		"label": "P"
	},
	{
		"value": "Q",
		"label": "Q"
	},
	{
		"value": "R",
		"label": "R"
	},
	{
		"value": "S",
		"label": "S"
	},
	{
		"value": "Š",
		"label": "Š"
	},
	{
		"value": "T",
		"label": "T"
	},
	{
		"value": "U",
		"label": "U"
	},
	{
		"value": "V",
		"label": "V"
	},
	{
		"value": "W",
		"label": "W"
	},
	{
		"value": "X",
		"label": "X"
	},
	{
		"value": "Y",
		"label": "Y"
	},
	{
		"value": "Z",
		"label": "Z"
	},
	{
		"value": "Ž",
		"label": "Ž"
	},
]